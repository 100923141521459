import { React } from 'react';

const Card=({item})=>{
    return <div className='itemCard'>
        <div className='itemCardimg'>
            <img  src={item.smallPicUri}/>
        </div>
        <div className='itemCardDetails'>
            {item.summaryFields &&item.summaryFields.map((i,index)=>{
              if(typeof i == "object"){
                return <p key={index}> 
                    {i.strikedText}
                </p>
              }
              else{
                  return <p key={index}> {i}</p>
              }
              
             
            })
        }
            <div className='Addtocart'>
                Add To Cart
            </div>
        </div>
    </div>
}

export default Card
