import { React, useState, useEffect, useContext } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import "../style/home.css";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import GoogleIcon from "../assets/google.png";
import Soclialbutton from "../components/socialbuttons";
import AppleButton from "../assets/apple.png";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Loction from "../assets/location.png";
import Phone from "../assets/call.png";
import Videocard from "../assets/videocard.png";
import Previous from "../assets/pastWhite.png";
import Pause from "../assets/pauseWhite.png";
import Forward from "../assets/forwardWhite.png";
import ItemCard from "../components/itemCard";
import Logo from "../assets/logo.png";
import axios from "axios";
import Slider from "react-animated-slider";
import InfiniteScroll from "react-infinite-scroll-component";
import 'react-animated-slider/build/horizontal.css';
import { AppContext } from "../context/AppContext";
let fallbackimg =
  "https://storage.googleapis.com/vmallapppublic/categoryImages/Exotic%20Fruits.jpg";
let colorselection = [
  { fromcolor: "rgba(143, 216, 255, 1)", tocolor: "rgba(130, 170, 255, 1)" },
  { fromcolor: "rgba(81, 130, 234, 1)", tocolor: "rgba(143, 216, 255, 1)" },
  { fromcolor: "rgba(250, 123, 148, 1)", tocolor: "rgba(255, 184, 198, 1)" },
  { fromcolor: "rgba(192,209,71,0.3)", tocolor: "rgba(130, 170, 255, 1)" },
  { fromcolor: "rgba(30, 130, 200, 1)", tocolor: "rgba(45, 216, 255, 1)" },
  { fromcolor: "rgba(250, 123, 148, 1)", tocolor: "rgba(255, 184, 198, 1)" },
];
let orfferlist_ = [
  { text: "Refer a friend and get reward when they order", color: "#34BEDA" },
  {
    text: "Get referred and install app and order",
    color: "rgba(81, 130, 234, 1)",
  },
  { text: "Get loyalty rewards", color: "#FA8FA3" },
];

function Home() {
  const { privacyRoute, fetchPrivacyContentFile, setPrivacyRoute } = useContext(AppContext);
  const { storeId } = useParams();
  const { pathname } = useLocation();
  const [productload, setproductload] = useState(false);
  const [forceload, setforceload] = useState(0);
  const [loading, setloading] = useState(false);
  const [welcomeLink, setwelcomeLink] = useState("");
  const [storeinfo, setstoreinfo] = useState("");
  const [category, setcategory] = useState([]);
  const [offer, setoffer] = useState(null);
  const [storeCatagory, setstorecatagory] = useState([]);
  const [offerlist, setofferlist] = useState(orfferlist_);
  const [selectedCategory, setseletedCategory] = useState(null);
  const [pageno, setpageno] = useState(0);
  const [product, setproduct] = useState([]);
  const [searchString, setsearchString] = useState("");
  const [youtubelink, setyoutubelink] = useState(
    "https://www.youtube.com/embed/xIkbm92MSk8"
  );

  let addDefaultSrc = (ev) => {
    ev.target.src = fallbackimg;
  };
  const getStoreData = async (storeId) => {
    setloading(true);

    const response = await axios.get(
      `https://us-central1-vmallapp.cloudfunctions.net/getWebSiteData?storeId=${storeId}`
    );

    setloading(false);
    if (response.data == "") {
      setproductload(false);
    }
    if (response.data) {
      setwelcomeLink(response.data.welcomeLink);
      var catString = "";
      response.data.categories.forEach((element) => {
        catString = catString + "," + element;
      });

      if (response.data.extraPathSetting) {
        const { route, htmlpath } = response.data.extraPathSetting;
        // set privacy content in context
        fetchPrivacyContentFile(htmlpath);
        setPrivacyRoute(route);
      }

      setstorecatagory(response.data.categories);
      setstoreinfo(response.data);
      setofferlist(
        response.data.offers.filter((i) => i.displayInUI && i.isActive)
      );
      let offer = await response.data.offers.find(
        (i) => i.displayInUI && i.isActive
      );
      setoffer(offer);
    }
  };

  let loadProd = () => {
    let { currentpgnumber, maxpagenumber } = pageno;

    if (Number(currentpgnumber) + 1 < Number(maxpagenumber)) {
      getProductInfo(currentpgnumber + 1);
    }
  };
  useEffect(() => {
    if (storeinfo) {
      setTimeout(() => {
        getProductInfo();
      }, 200);
    }
  }, [storeinfo]);
  let getProductInfo = async (pagenoinner = 0) => {
    let category = [...storeCatagory];

    let pagenumber = 0;
    let url = "https://us-central1-vmallapp.cloudfunctions.net/getStoreDataV2";
    let data = {
      data: {
        storeId: storeId,
        deviceId: "24wrwe",
        category:
          forceload == 2 ? "" : selectedCategory ? selectedCategory : "",
        pageToGet: pagenoinner,
        searchString: forceload == 1 ? "" : searchString,
        searchEnabled: true,
      },
    };
    setproductload(true);
    axios
      .post(url, data)
      .then(async (res) => {
        if (res) {
          var countProd = [];

          let catagoryListGenerate = [];

          category.map(async (c) => {
            let catcount = [];

            res.data.result.data.storeItems.find((i) => {
              if (i.category == c) {
                catcount.push(i);
              }
            });
            catagoryListGenerate.push({ name: c, count: catcount.length });
            catagoryListGenerate.sort((a, b) => {
              return b.count - a.count;
            });
          });

          setcategory(catagoryListGenerate);

          if (Number(pagenumber) != 0) {
            setproduct([...product, ...res.data.result.data.storeItems]);
          } else {
            setproduct(res.data.result.data.storeItems);
          }

          setpageno({
            currentpgnumber: pagenumber,
            maxpagenumber: res.data.result.data.totalPages,
          });

          setproductload(false);
          setforceload(0);
        }
      })
      .catch((err) => {
        console.log(err, "rer");
      });
  };

  let forceloadCatgory = () => {
    setseletedCategory("");
    setforceload(2);
  };

  useEffect(() => {
    async function anyNameFunction() {
      await getStoreData(storeId);
    }
    anyNameFunction();
  }, []);

  useEffect(() => {
    if (forceload != 0) {
      getProductInfo();
    }
  }, [forceload]);

  let searchinput = (e) => {
    setsearchString(e.target.value);
  };
  useEffect(() => {
    if (selectedCategory) {
      getProductInfo();
    }
  }, [selectedCategory]);

  let SearchStringcancel = async () => {
    setsearchString("");

    setforceload(1);
  };
  useEffect(() => {
    if (selectedCategory) {
      setseletedCategory("");
      setTimeout(() => {
        setTimeout(() => {
          getProductInfo();
        }, 300);
      }, 200);
    } else {
      setTimeout(() => {
        getProductInfo();
      }, 300);
    }
  }, [searchString]);
  if (loading)
    return (
      <div className="homecontainer">
        {" "}
        <h1>Please open the link from a mobile phone</h1>
      </div>
    );
  else
    return (
      <div className="homecontainer">
        {storeinfo && (
          <div className="banner">
            <div className="layerblack">
              <div className="StoreInformation">
                <h1>{storeinfo.storeTitle} </h1>
                <p>{storeinfo.storeCategory}</p>
              </div>
            </div>

            {storeinfo.storeCategory && (
              <img
                className="bannerimage"
                src={`https://storage.googleapis.com/vmallapppublic/categoryImages/masterStore${storeinfo.storeCategory}.jpg`}
                alt={storeinfo.storeCategory}
                onError={addDefaultSrc}
              />
            )}
          </div>
        )}
        {storeinfo && (
          <div className="SearchAndCatagory">
            <div className="Search">
              <SearchIcon />
              <input
                className="searchbar"
                type="text"
                value={searchString}
                onChange={searchinput}
                placeholder="Search Something..."
              />
              <CloseIcon
                className="closeicon"
                onClick={SearchStringcancel}
                style={{ opacity: searchString ? 1 : 0 }}
              />
            </div>
            <div className="catagory">
              <div className="catagoryContainer">
                {category &&
                  category.map((i, indexi) => {
                    return (
                      <div
                        onClick={() => {
                          setseletedCategory(
                            i.name == selectedCategory
                              ? forceloadCatgory()
                              : i.name
                          );
                        }}
                        className={`${
                          selectedCategory == i.name && "activecat"
                        }`}
                        key={indexi}
                      >
                        <div
                          className={`${i.count != 0 ? "count" : "nocount"}`}
                        >
                          {i.count}
                        </div>
                        {i.name}
                      </div>
                    );
                  })}
              </div>
            </div>
           
              {storeinfo && offer && (
                // <div>
                //   <p>{offer.offerText}</p>
                // </div>
              
                <Slider infinite  className="offerbanner" >
                  {offerlist.map((slide, index) => (
                    
                      <p key={index}>{slide.offerText}</p>
                   
                  ))}
                </Slider>
                
              )}
        
          </div>
        )}
        {storeinfo && (
          <>
            <p className="instruction">
              Get Full experience by downloading app.
            </p>
            <div className="socialcontainer" style={{ marginTop: 10 }}>
              <Soclialbutton
                icon={GoogleIcon}
                name="Google Play"
                link={welcomeLink}
                title="GET IT ON"
              />
              <Soclialbutton
                icon={AppleButton}
                name="App Store"
                link={welcomeLink}
                title="Download on the"
              />
            </div>
          </>
        )}

        {productload && (
          <div className="loading">
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          </div>
        )}

        {storeinfo && product && !productload && product.length == 0 && (
          <div className="NoProduct">
            <p>No Products Found</p>
          </div>
        )}
        {product && !productload && product.length > 0 && (
          <InfiniteScroll
            height={800}
            className="scrollContainer"
            style={{overflowY:product.length>5?'scroll':'auto'}}
            dataLength={product.length} //This is important field to render the next data
            next={loadProd}
            hasMore={
              Number(pageno.currentpgnumber) + 1 < Number(pageno.maxpagenumber)
            }
            loader={<h4>Loading...</h4>}
            endMessage={<p style={{ textAlign: "center" }}></p>}
          >
            <div className="productContainer">
              {product.map((i, index) => {
                return <ItemCard item={i} key={index} />;
              })}
            </div>
          </InfiniteScroll>
        )}

        {storeinfo && offerlist.length > 0 && (
          <div className="offerContainer">
            {offerlist.length > 0 && (
              <div>
                <p style={{ fontSize: "24px" }}>Recommendations for you</p>
                <p>Big Deals For You!</p>
              </div>
            )}
            <div className="cardcontainer offer">
              {offerlist.length > 0 &&
                offerlist.map((i, index) => {
                  let colorindex = Math.floor(Math.random() * 5) + 1;

                  return (
                    <div
                      className="offercard"
                      style={{ background: `${i.color}` }}
                      key={index}
                    >
                      <img src={Logo} className="logo" alt={"logo"} />
                      <div
                        className="linerlayer"
                        style={{
                          background: `linear-gradient(278deg, ${colorselection[colorindex].tocolor} 0%, ${colorselection[colorindex].fromcolor} 100%)`,
                        }}
                      >
                        <p className="offername">{i.offerText}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        {!storeinfo && (
          <div className="NostoreFound">
            <div>
              <h1>No Store found</h1>
              <p className="instruction">
                Get Full experience by downloading app.
              </p>
              <div className="socialcontainer" style={{ marginTop: 10 }}>
                <Soclialbutton
                  icon={GoogleIcon}
                  name="Google Play"
                  link={welcomeLink}
                  title="GET IT ON"
                />
                <Soclialbutton
                  icon={AppleButton}
                  name="App Store"
                  link={welcomeLink}
                  title="Download on the"
                />
              </div>
            </div>
          </div>
        )}
        {storeinfo && (
          <div className="details">
            <div className="storeinfo">
              <div className="storedetails">
                <h1>{storeinfo.storeTitle} </h1>
                <p>{storeinfo.storeCategory}</p>
                <div className="contactinfo">
                  <div className="icons">
                    <img src={Phone} alt="phone" />
                  </div>

                  <p> {storeinfo.phoneNumber}</p>
                </div>
                <div className="contactinfo">
                  <div className="icons">
                    <img src={Loction} alt="Loction" />
                  </div>
                  <p>{storeinfo.address}</p>
                </div>
              </div>
              <div className="appinfo">
                <div className="videoconariner">
                  <iframe
                    width="100%"
                    height="315"
                    src={youtubelink}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <div className="overflayicon">
                    <div className="videoicon">
                      <img src={Previous} alt={"Previous"} />
                      <img src={Pause} alt={"Pause"} />
                      <img src={Forward} alt={"Forward"} />
                    </div>
                  </div>
                </div>
                Please click on the download link from mobile to install app and
                get deals.
              </div>
            </div>
          </div>
        )}
        <Link to={`${pathname}/${privacyRoute}`}>Privacy Policy</Link>
      </div>
    );
}

export default Home;
