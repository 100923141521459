import { React, useState, useEffect } from "react";
import GoogleIcon from "../assets/google.png";
import Soclialbutton from "../components/socialbuttons";
import AppleButton from "../assets/apple.png";
const Footer =()=>{
    return <div className='footer'>
       <div className='footertext'>
       <p className='footermain'>Try Seva-u</p>
        <p>Download Seva-U for free, simple,Easy to use on iOS and Android.</p>
       </div>
        <div className="socialcontainer" style={{ marginTop: 10 }}>
              <Soclialbutton
                icon={GoogleIcon}
                name="Google Play"
                link={''}
                title="GET IT ON"
              />
              <Soclialbutton
                icon={AppleButton}
                name="App Store"
                link={''}
                title="Download on the"
              />
            </div>
    </div>
}

export default Footer