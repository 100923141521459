import React from 'react'
import { Link } from 'react-router-dom'

const SocialButton=({icon,name,link,title})=>{
    return <div className='soclialbutton'>
        <div className='icon'>
            {icon && <img  src={icon} alt={name} />}
        </div>
        <div className='name'>
                <p>{title}</p>
                <a href={link}>{name}</a>
        </div>
    </div>
}


export default SocialButton