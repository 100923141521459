import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import Home from "./screen/home";
import About from "./screen/about";
import Contact from "./screen/contact";
import Footer from './components/footer'
import SevaUApp from "./screen/sevauapp";
import PrivacyPolicy from "./screen/privacypolicy";
import "./App.css";

const App = () => {
  const { pathname } = useLocation();
    return (
        <>
        <div className="App">
          <Switch>
            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
            <Route exact path="/:storeId" component={Home}></Route>
            <Route exact path="/about" component={About}></Route>
            <Route exact path="/contact" component={Contact}></Route>
            <Route exact path="/sevauapp/:storeId" component={SevaUApp}></Route>
            <Route exact path={`/:storeId/:privacyId`} component={PrivacyPolicy}></Route>
          </Switch>       
        </div>
        <Footer />
        </>
    );
  }

export default App;
