import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { get } from "axios";
import '../style/privacy.css';

const PrivacyPolicy = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { privacyRoute, privacyContent = '', fetchPrivacyContentFile, setPrivacyRoute} = useContext(AppContext);

    const history = useHistory();
    const storePaths = history.location.pathname.split('/');

    const getStoreData = async (storeId) => {
        setIsLoading(true);

        const response = await get(
        `https://us-central1-vmallapp.cloudfunctions.net/getWebSiteData?storeId=${storeId}`
        );
        if (response.data.extraPathSetting) {
            const { route, htmlpath } = response.data.extraPathSetting;
            // set privacy content in context
            fetchPrivacyContentFile(htmlpath);
            setPrivacyRoute(route);
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }
    // If content is present then display that
    const onBack = () => {
        history.push(`/${storePaths[1]}`);
    }

    useEffect(() => {
        if (!(privacyRoute && privacyContent)) {
            getStoreData(storePaths[1]);
        }
        return () => {
            clearTimeout();
        }
    }, [])

    // Conditional page render
    if (isLoading)
    return (
      <div className="privacy-container">
        <h1 className="loading-privacy">Please open the link from a mobile phone</h1>
      </div>
    );

    if ((storePaths[2] === privacyRoute) && privacyContent) {
        return (
            <div className="privacy-container" dangerouslySetInnerHTML={{__html: privacyContent}} />
        )
    }
    // Otherwise 404
    return (
        <div className="privacy-container not-found">
            <Typography variant="h1">
              404
            </Typography>
            <Typography variant="h6">
              The page you’re looking for doesn’t exist.
            </Typography>
            <Button variant="contained" className="go-back-btn" onClick={onBack}>Back Home</Button>
        </div>
    )
}

export default PrivacyPolicy;