import { createContext, useState } from "react";

export const AppContext = createContext();

export const AppContextProvider = (props) => {
    const [privacyRoute, setPrivacyRoute] = useState();
    const [privacyContent, setPrivacyContent] = useState('');

    const fetchPrivacyContentFile = (filePath) => {
        fetch(filePath, {
            method: "GET",
            headers: {
                'Content-Type': 'file.type',
                origin: '*',
                "Access-Control-Allow-Origin": '*',
                "Access-Control-Allow-Methods": ["GET", "PUT", "OPTIONS"],
                "Access-Control-Allow-Headers": ["accept", "origin", "authorization", "content-type"]
            }
        })
        .then((responseFile) => {
            responseFile.text().then((responseText) => {
                setPrivacyContent(responseText.replace(/\r?\n|\r/g, ""));
            }).catch()
        })
        .catch((e) => {
            console.error("Error: ", e);
        })
    }

    const contextValues = {
        privacyRoute,
        privacyContent, 
        setPrivacyRoute,
        fetchPrivacyContentFile,
    }
    return (
        <AppContext.Provider value={contextValues}>
            {props.children}
        </AppContext.Provider>
    )
}